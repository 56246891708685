<template>
  <div class="c_pagination_control" :style="{ '--justify': justifyContent }" v-if="totalButtons > 1">
    <button
      class="c_pagination_control__button"
      v-if="buttonsPageIndex > 0"
      @click="onControlButtonClicked('back')"
      :aria-label="$t('Previous page')"
    >
      <IconSVG class="c_box--margin--auto" handle="arrow_left" width="5" height="13" aria-hidden="true" />
    </button>
    <button
      class="c_pagination_control__button"
      :class="{ 'c_pagination_control--active': index === buttonIndex }"
      v-for="(item, index) in buttons[buttonsPageIndex]"
      :key="index"
      @click="onPageIndexClicked(index)"
    >
      {{ item.index }}
    </button>
    <button
      class="c_pagination_control__button"
      v-if="buttonsPageIndex < buttons.length - 1"
      @click="onControlButtonClicked('next')"
      :aria-label="$t('Next page')"
    >
      <IconSVG class="c_box--margin--auto" handle="arrow_right" width="5" height="13" aria-hidden="true" />
    </button>
  </div>
</template>

<script>
import IconSVG from 'Components/icon_svg/IconSVG.vue';

export default {
  components: {
    IconSVG,
  },
  data() {
    return {
      buttonIndex: 0,
      buttonsPageIndex: 0,
      currentPageResults: [],
    };
  },
  props: {
    itemsToPage: { type: Array, required: true },
    justifyContent: { type: String, default: 'flex-start' }, // flex-start, center, flex-end
    resultsPerPage: { type: Number, default: 10 },
    totalButtonsShown: { type: Number, default: 5 },
  },
  computed: {
    buttons() {
      let pageIndex = 0;
      let buttonIndex = 0;
      const buttons = [];
      for (let i = 0; i < this.totalButtons; i++) {
        if (!buttons[pageIndex]) {
          buttons[pageIndex] = [];
        }

        buttons[pageIndex].push({ index: i + 1, startingPagedItemIndex: i * this.resultsPerPage });

        if (buttonIndex === this.totalButtonsShown - 1) {
          buttonIndex = 0;
          pageIndex++;
        } else {
          buttonIndex++;
        }
      }
      return buttons;
    },
    totalButtons() {
      return Math.ceil(this.itemsToPage.length / this.resultsPerPage);
    },
  },
  methods: {
    onControlButtonClicked(direction) {
      if (direction === 'back') {
        this.buttonsPageIndex--;
      } else {
        this.buttonsPageIndex++;
      }
      this.buttonIndex = 0;
      this.updatedPagedItems();
    },
    onPageIndexClicked(index) {
      if (this.buttonIndex !== index) {
        this.buttonIndex = index;
        this.updatedPagedItems();
      }
    },
    reset() {
      this.buttonsPageIndex = 0;
      this.buttonIndex = 0;
      this.updatedPagedItems();
    },
    updatedPagedItems() {
      if (this.buttons.length) {
        const startingPageItemsIndex = this.buttons[this.buttonsPageIndex][this.buttonIndex].startingPagedItemIndex;
        this.currentPageResults = [];

        for (let i = 0; i < this.itemsToPage.length; i++) {
          if (
            this.itemsToPage[i] &&
            i >= startingPageItemsIndex &&
            i <= startingPageItemsIndex + this.resultsPerPage - 1
          ) {
            this.currentPageResults.push(this.itemsToPage[i]);
          }
        }
      } else {
        this.currentPageResults = this.itemsToPage;
      }

      this.$emit('pagedItemsUpdated');
    },
  },
  watch: {
    itemsToPage() {
      this.updatedPagedItems();
    },
  },
  mounted() {
    if (this.itemsToPage.length) {
      this.updatedPagedItems();
    }
  },
};
</script>

<style lang="scss">
.c_pagination_control {
  $self: &;
  display: grid;
  grid-template-columns: repeat(auto-fit, 2.1em);
  align-items: center;
  justify-content: var(--justify);

  @at-root .app--isEdge15orDown & {
    display: flex;
    flex-flow: row wrap;
  }

  @at-root #{$self}__button {
    $button: &;
    @include button_reset;
    height: 2.1em;
    text-align: center;
    transition: background-color 0.5s ease-out;

    &:not(#{$self}--active):hover,
    &:not(#{$self}--active):focus {
      background-color: $color_gray_235;
    }

    @at-root #{$self}--active {
      background-color: $color_red;
    }
  }
}
</style>
