<template>
  <header class="product_2022 c_box--black" ref="c_product_2022_header">
    <RelativeAnchor
      v-if="data.displayInHeaderQuicklinks && data.productModel"
      :id="data.productBrand + ' ' + data.productModel"
    />
    <div class="c_wrapper_2022 product_2022__flex">
      <div :class="hasSidebar ? 'product_2022__content' : 'product_2022__content--no-sidebar'">
        <h1 class="product_2022__overview__title c_2022--h1_graphik">
          <span v-if="data.productBrand" class="product_2022__overview__title__brand">{{ data.productBrand }}</span>
          <span v-if="data.productModel" class="product_2022__overview__title__model">{{ data.productModel }}</span>
          <span v-if="data.productDescriptors" class="product_2022__overview__title__descriptors">{{
            data.productDescriptors
          }}</span>
        </h1>
        <div
          v-if="data.productDescription"
          class="product_2022__overview__description c_2022--lead"
          v-html="data.productDescription"
        ></div>
        <div v-if="data.headerCta.length || data.gallery.length || data.sidebarButton.length">
          <ul class="product_2022__header_links">
            <li class="product_2022__header_link" v-for="(link, index) in data.headerCta" :key="index">
              <a
                class="c_button_2022"
                :class="link.styling ? 'c_button_2022__primary' : 'c_button_2022__secondary'"
                :href="getHref(link)"
                :target="getTarget(link)"
                :aria-label="link.ariaLabel || link.labelText || null"
                >{{ link.buttonLabel }}</a
              >
            </li>
            <li class="product_2022__header_link" v-if="data.gallery.length">
              <Button :key="index" @onClick="triggerOverlay()" class="c_button_2022 c_button_2022__secondary"
                >{{ $t('Photo Gallery') }}
              </Button>
            </li>
            <li class="product_2022__sidebar_mobile" v-if="data.sidebarButton.length">
              <a
                class="c_button_2022 c_button_2022__primary"
                :href="getHref(data.sidebarButton[0])"
                :target="getTarget(data.sidebarButton[0])"
                :aria-label="data.sidebarButton[0].ariaLabel || data.sidebarButton[0].labelText || null"
                >{{ data.sidebarButton[0].buttonLabel }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div v-if="hasSidebar || (data.sidebarButton && data.sidebarButton.length)" class="product_2022__sidebar">
        <a
          v-if="data.sidebarButton.length"
          class="c_button_2022 c_button_2022__primary"
          :href="getHref(data.sidebarButton[0])"
          :target="getTarget(data.sidebarButton[0])"
          :aria-label="data.sidebarButton[0].ariaLabel || data.sidebarButton[0].labelText || null"
          >{{ data.sidebarButton[0].buttonLabel }}</a
        >
        <ul class="product_2022__header_section_links">
          <li v-for="(link, i) in anchors" :key="i">
            <a :href="link.anchor" :aria-label="link.title">
              {{ link.title }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <Overlay class="c_gallery_grid__overlay" rounded ref="grid_gallery_overlay" :show-close-button="true" theme="image">
      <Slider
        class="product__overview__gallery__overlay_slider"
        captions
        video-player
        :on-overlay="true"
        :disable-hover-effect="true"
        :main-height="$mq === 'sm' ? '350px' : '506px'"
        :slide-image-sources="gallerySources"
        ref="overview_gallery_slider"
        :arrows="'outside'"
      />
    </Overlay>
  </header>
</template>

<script>
import Button from 'Components/button/Button.vue';
import Overlay from 'Components/overlay/Overlay.vue';
import RelativeAnchor from 'Components/relative_anchor/RelativeAnchor.vue';
import Slider from 'Components/slider/Slider.vue';
import { openPdfsInNewTab } from 'JS/global.js';

export default {
  components: {
    Button,
    Overlay,
    Slider,
    RelativeAnchor,
  },
  props: {
    data: Object,
    anchors: Array,
  },
  data() {
    return {
      hasSidebar: null,
      gallerySources: [],
    };
  },
  methods: {
    triggerOverlay() {
      this.$refs.grid_gallery_overlay.show();
    },
    getHref(btn) {
      if (!btn.custom?.length) {
        let url = btn.entry?.length ? this.localePath(`/${btn?.entry[0]?.uri}`) : btn.asset[0]?.url;
        if (url === '/undefined') {
          const delimiters = [
            'http://localhost:3000',
            'https://kodakcraftdev.z13.web.core.windows.net',
            'https://webstag.kodak.com',
            'https://www.kodak.com',
          ];
          url = btn.entry?.length ? this.localePath(`/${btn?.entry[0]?.url}`) : btn.asset[0]?.url;
          for (const delimiter of delimiters) {
            const parts = url.split(delimiter);
            if (parts.length === 2) {
              return parts[1];
            }
          }
        }
        return url;
      }
      return btn.custom || null;
    },
    getTarget(btn) {
      const href = this.getHref(btn);
      if (typeof href === 'string' && href.includes('.pdf')) {
        return '_blank';
      }
      return btn.openInANewWindow ? '_blank' : null;
    },
  },
  created() {
    if (this.data.sidebar) {
      this.hasSidebar = true;
    }
    if (this.data.gallery.length) {
      this.data.gallery.forEach((item) => {
        this.gallerySources.push({
          alt: item.title,
          sourcesMain: [
            { srcset: item.galleryEnlarged.srcWebp, type: 'image/webp' },
            { srcset: item.galleryEnlarged.srcset },
          ],
          sourcesThumbnails: [
            { srcset: item.galleryThumbnail.srcWebp, type: 'image/webp' },
            { srcset: item.galleryThumbnail.srcset },
          ],
          caption: item.caption || false,
          videoId: item.videoId || false,
          videoSource: item.videoSource || '',
        });
      });
    }
  },
  mounted() {
    openPdfsInNewTab(this.$refs?.c_product_2022_header);
  },
};
</script>

<style lang="scss">
.product_2022 {
  $self: &;
  $product_2022_padding: px_to_rem(48);

  padding: $product_2022_padding 0;
  @at-root #{$self}__flex {
    display: flex;
    @at-root #{$self}__content--no-sidebar {
      width: 100%;
      flex: 0 0 100%;
      .c_button_2022 {
        margin-bottom: px_to_rem(20); 
      }
      @include mq($mq_md_to_lg) {
        width: 75%;
        flex: 0 0 75%;
      }
    }
    @at-root #{$self}__content {
      padding-right: px_to_rem(120px);
      .c_button_2022 {
        margin-bottom: px_to_rem(20px);
      }
      @media (max-width: 1024px) {
        padding-right: px_to_rem(40px);
      }
      @media (max-width: 700px) {
        padding-right: 0;
      }
    }
    @at-root #{$self}__sidebar {
      flex: 0 0 10%;
      min-width: px_to_rem(185);
      margin-left: auto;
      .c_button_2022 {
        width: 100%;
      }
      @media (max-width: 700px) {
        display: none;
      }
    }
    @at-root #{$self}__header_section_links {
      padding-top: px_to_rem(30);
      @include mq($mq_sm_to_md) {
        padding-top: px_to_rem(60);
      }
      li {
        margin-bottom: px_to_rem(10);
      }
      a {
        color: $color_white;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  @at-root #{$self}__overview {
    $overview: &;
    @at-root #{$overview}__title {
      $title: &;
      margin-bottom: px_to_rem(50);
      @at-root #{$title} {
        color: $color_white;
        @include font('graphik_semibold');
      }
      @at-root #{$title}__brand {
        color: $color_red;
      }
      @at-root #{$title}__descriptors {
        @include font('graphik_regular');
      }
    }
    @at-root #{$overview}__description p {
      color: $color_white;
      strong {
        @include font('graphik_semibold');
        color: $color_white;
      }
      @media (max-width: 700px) {
        color: $color_gray_191;
      }
    }
    @at-root #{$self}__sidebar_mobile {
      display: none;
      @media (max-width: 700px) {
        display: inline-block;
      
      }
    }
  }

  .product_2022__sidebar_mobile {
    display: none;
    @media (max-width: 700px) {
      display: inline-block;
      margin-top: 0px;
    }
  }

  @at-root #{$self}__header_links {
    margin-top: px_to_rem(50);
    display: flex;
    flex-wrap: wrap;
    @at-root #{$self}__header_link {
      margin-right: px_to_rem(20px);
      
    }
  }
  .c_overlay {
    $overlay: &;
    .agile__list {
      overflow: visible; // allows captions to display below images
    }
    .c_image_bg__image,
    .c_image_bg img {
      height: auto;
      object-fit: contain;
      @include mq($mq_sm_to_md) {
        height: 100%;
        object-fit: contain;
      }
    }
    .c_video.c_video--background {
      width: 100%;
      height: 0;
      padding-bottom: 56%;
    }
    @media (max-width: $mq_sm_to_md + 'px') {
      .c_slider--overlay {
        width: 80vw !important;
      }
      .agile__actions {
        .agile__nav-button {
          &.agile__nav-button--prev {
            left: px_to_rem(-45);
          }
          &.agile__nav-button--next {
            right: px_to_rem(-45);
          }
        }
      }
    }
  }
}
</style> 