<template>
  <Button class="c_featured_content" v-bind="{ href: getHref, reset: true, unstyle: true }">
    <div class="c_featured_content__image" v-if="mediaSources.length">
      <MediaImage :sources="mediaSources" :alt="content.title" />
    </div>
    <div class="c_featured_content__text">
      <div class="c_featured_content__date c_2022--body-sm" v-if="formattedPostDate || displayDuration">
        <span v-if="formattedPostDate">{{ formatPostDate }}</span>
        <span v-if="displayDuration">{{ generateReadTime }} {{ $t('min read') }}</span>
      </div>
      <h3 class="c_2022--h3_graphik">
        {{ content.title }}
      </h3>
      <div class="c_2022--body" v-if="displayArticleSummary && summary" v-html="summary"></div>
    </div>
  </Button>
</template>

<script>
import MediaImage from 'Components/image/MediaImage.vue';
import Button from 'Components/button/Button.vue';
import { translatePostDate } from 'JS/global.js';

export default {
  props: {
    content: Object,
    displayArticleSummary: { type: Boolean, default: false },
  },
  components: {
    MediaImage,
    Button,
  },
  data() {
    return {
      displayDuration: false,
      formattedPostDate: false,
      mediaSources: [],
      summary: null,
    };
  },
  computed: {
    formatPostDate() {
      const date = this.content.eventDateStartTime || this.content.postDate;
      return translatePostDate(date, this.$i18n?.locale);
    },
    generateReadTime() {
      let words = '';
      this.content.blogContent.forEach((paragraph) => {
        words += ' ' + paragraph.text;
      });
      const wordsFormatted = words.replace(/<[^>]+>/g, '');
      const numOfWords = wordsFormatted.split(' ').length;
      const time = Math.ceil(numOfWords / 225);
      return time;
    },
    getHref() {
      if (['event', 'eventV2'].includes(this.content.typeHandle)) {
        return this.content?.eventsCategory?.[0].slug + '/events';
      } else {
        return this.content?.uri;
      }
    },
  },
  created() {
    // Adjust data values based on entry types
    switch (this.content?.typeHandle) {
      case 'blog':
      case 'customerStory':
      case 'pressRelease': {
        if (this.content.typeHandle !== 'customerStory') {
          this.displayDuration = this.formattedPostDate = true;
        }
        if (this.content.blogFeaturedImage?.[0].filterLandscape) {
          this.mediaSources = [this.content.blogFeaturedImage?.[0].filterLandscape];
        } else {
          this.mediaSources = [this.content.blogFeaturedImage?.[0].filterPageFeaturedImage];
        }
        this.summary = this.content.relatedEntryDescription || null;
        break;
      }
      case 'productPage2022':
      case 'productPage': {
        this.mediaSources = [this.content.productFeaturedImage?.[0].filterLandscape];
        this.summary = this.content.relatedEntryDescription || null;
        break;
      }
      case 'event': {
        this.formattedPostDate = true;
        this.mediaSources = [this.content.eventFeaturedImage?.[0].filterPageFeaturedImage];
        this.summary = this.content.relatedEntryDescription || null;
        break;
      }
      case 'eventV2': {
        this.formattedPostDate = true;
        this.displayDuration = this.formattedPostDate = false;
        this.mediaSources = [this.content.eventFeaturedImage?.[0]?.filterLandscape];
        this.summary = this.content.relatedEntryDescription || null;
        break;
      }
      case 'landingPage2023':
      case 'landingPage': {
        const lpImage = this.content?.landingPageFeaturedImage?.[0]?.lpImageGridLandscape;
        lpImage ? (this.mediaSources = [lpImage]) : (this.mediaSources = []);
        this.summary = this.content?.relatedEntryDescription || null;
        break;
      }
    }
  },
};
</script>

<style lang="scss">
.c_featured_content {
  $self: &;

  text-decoration: none;
  background-color: $color_white;
  color: $color_black;
  @at-root #{&}__image {
    overflow: hidden;
    height: auto;
    @include mq($mq_md_to_lg) {
      height: px_to_rem(240);
    }
    @include mq($mq_sm_to_md) {
      height: px_to_rem(180);
    }
    figure {
      height: 100%;
    }
    .c_image {
      display: block;
      height: 100%;
      img {
        object-fit: cover;
        height: 100%;
      }
    }
  }
  @at-root #{&}__text {
    padding: px_to_rem(10) px_to_rem(16) px_to_rem(30) px_to_rem(16);
    h3 {
      @include font('graphik_semibold');
      &:hover {
        text-decoration: underline;
      }
    }
    p,
    .c_2022--body {
      margin-top: px_to_rem(10);
      @include font('graphik_regular');
    }
  }
  @at-root #{&}__date {
    display: flex;
    justify-content: space-between;
    @include font('graphik_regular');
    color: $color_gray_112;
    margin-bottom: px_to_rem(10);
  }
}
</style>
