<template>
  <Flex class="c_related_resource_container">
    <div class="c_related_resource" :class="resourceObj.cardStyle" :style="hasBackgroundImage(resourceObj)">
      <div class="c_related_resource__category c_2022--body" v-if="resourceObj.category.title">
        {{ resourceObj.category.title }}
      </div>
      <div class="c_related_resource__title_container">
        <h2 class="c_2022--h2_graphik">
          <span class="c_related_resource__title" v-if="resourceObj.title">{{ resourceObj.title }}</span>
          <span class="c_related_resource__subtitle" v-if="resourceObj.subtitle">{{ resourceObj.subtitle }}</span>
        </h2>
      </div>
      <div class="c_related_resource__language c_2022--body" v-if="resourceObj.language !== 'Not set'">
        {{ resourceObj.language }}
      </div>
      <div class="c_related_resource__description c_2022--body" v-if="resourceObj.description">
        {{ resourceObj.description }}
      </div>
      <div class="c_related_resource__button">
        <Button
          v-if="!resourceObj.videoId"
          :is="'a'"
          :class="[resourceObj.btnOutline, resourceObj.btnTextColor]"
          :href="resourceObj.linkOverride ? resourceObj.linkOverride : resourceObj.url"
          :aria-label="resourceObj.buttonLabel + ' ' + resourceObj.fileName"
          target="_blank"
        >
          {{ resourceObj.buttonLabel }}
        </Button>
        <Button
          type="button"
          @click="triggerOverlay(resourceObj.videoId)"
          reset
          unstyle
          :class="[resourceObj.btnOutline, resourceObj.btnTextColor]"
          :aria-label="$t('Open media in popup overlay')"
          v-if="resourceObj.videoId"
        >
          {{ resourceObj.buttonLabel }}
        </Button>
      </div>
    </div>
  </Flex>
</template>

<script>
import Flex from 'Components/flex/Flex.vue';

export default {
  components: {
    Flex,
  },
  props: {
    resourceObj: [Object, Array],
  },
  methods: {
    triggerOverlay(videoId) {
      this.$emit('overlay-triggered', videoId);
    },
    hasBackgroundImage(el) {
      // console.log('element', el);
      if (el.image?.src && el.cardStyle === 'c_related_resource__image-background') {
        return `background-image: url(${el.image.src})`;
      }
      return '';
    },
  },
};
</script>

<style lang="scss">
.c_related_resource_container {
  display: grid;
  grid-auto-flow: column;
  width: 100%;
  grid-auto-columns: minmax(0, 1fr);
  grid-column-gap: px_to_rem(15);
  padding-left: 0;
  padding-right: 0;
  @include mq($mq_sm_to_md) {
    padding-left: px_to_rem(10);
    padding-right: px_to_rem(10);
  }
}
.c_related_resource_container--noslider {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
  grid-column-gap: px_to_rem(15);
}
.c_related_resource {
  $self: &;
  border-radius: px_to_rem(10);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  padding: px_to_rem(23) px_to_rem(30) px_to_rem(32) px_to_rem(23);
  @include mq($mq_sm_to_md) {
    padding: px_to_rem(23) px_to_rem(35) px_to_rem(35) px_to_rem(23);
  }
  @at-root #{&}__white-background {
    border: 1px solid $color_gray_201;
  }
  @at-root #{&}__yellow-background {
    background-color: $color_yellow;
    background-repeat: no-repeat;
  }
  @at-root #{&}__image-background {
    background-size: cover;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.55);
    }
    & * {
      position: relative;
      z-index: 5;
    }
    &.c_related_resource {
      color: $color_white;
    }
  }
  @at-root #{&}__category {
    @include font('graphik_medium');
  }
  @at-root #{&}__title_container {
    margin-top: px_to_rem(10);
    @include mq($mq_sm_to_md) {
      margin-top: px_to_rem(30);
    }
    h2 {
      display: flex;
      flex-direction: column;
      @at-root #{$self}__title {
        @include font('graphik_medium');
      }
      @at-root #{$self}__subtitle {
        @include font('graphik_light');
      }
    }
  }
  @at-root #{&}__language {
    padding-bottom: px_to_rem(25);
    margin-top: px_to_rem(25);
    @include mq($mq_sm_to_md) {
      padding-bottom: px_to_rem(50);
    }
  }
  @at-root #{&}__description {
    @include font('graphik_medium');
  }
}
</style>
